

import { Component, Vue, Watch } from "vue-property-decorator";

import { CalendarEvent } from '@/types';

import { User } from "@/store/user/types";
import { endOfMonth, format, startOfMonth } from "date-fns";
import { fr, nlBE } from "date-fns/locale";
import { ROOT_ACTIONS } from "@/store/actions";
import { USER_GETTERS } from "@/store/user/getters";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import { collection, deleteDoc, doc, documentId, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { firestore } from "@/firebase";

@Component({
  components: {
    CreateEventDialog: () => import('@/components/dialogs/CreateEventDialog.vue'),
    UpdateEventDialog: () => import('@/components/dialogs/UpdateEventDialog.vue'),
    ConfirmationDialog
  }
})

export default class Index extends Vue {
  events = [] as CalendarEvent[];
  eventAttendeesCount: Record<string, number> = {}

  users: Record<string, User> = {};
  selectedEditEvent: CalendarEvent | null = null;

  get user(): User {
    return this.$store.getters[USER_GETTERS.ACTING_USER]
  }

  date = new Date().toISOString().split('T')[0];

  loading = false;
  deleteLoading = false;

  dialogs: Record<string, any> = {
    createEvent: false,
    editEvent: false,
    removeEvent: false
  }

  async toggleEditDialog(event: CalendarEvent) {
    this.selectedEditEvent = event;
    this.dialogs.editEvent = true;
  }

  toggleRemoveDialog(event: CalendarEvent) {
    this.selectedEditEvent = event;
    this.dialogs.removeEvent = true;
  }

  async removeEvent() {
    if (this.selectedEditEvent) {
      this.deleteLoading = true;
      if (this.selectedEditEvent.id) {
        await deleteDoc(doc(firestore, 'webinars', this.selectedEditEvent.id))
      }
      this.dialogs.removeEvent = false;
      this.fetchEvents();
      this.deleteLoading = false;
    }
  }

  handleEventClick({ event }: any) {
    const foundEvent = this.events.find((e) => e.id === event.id);
    if(foundEvent) {
      this.$router.push(`webinars/${foundEvent.id}`)
    }
  }


  @Watch('date', { immediate: true })
  async fetchEvents() {
    this.loading = true;
    const d = new Date(this.date);

    this.events = [] as CalendarEvent[];
    let fetchedEvents = await getDocs(query(collection(firestore, 'webinars'), where('start', '>=', startOfMonth(d)), where('start', '<=', endOfMonth(d))));

    const events = [] as CalendarEvent[];
    for (let event of fetchedEvents.docs) {
      const attendeeds = await getDocs(query(collection(firestore, 'event-attendees'), where('webinarId', '==', event.id)))
      events.push({
        id: event.id,
        ...event.data(),
      } as CalendarEvent)
      this.eventAttendeesCount[event.id] = attendeeds.size
    }

    const users = {} as Record<string, User>;

    if (events.map((e) => e.coach).length !== 0) {
      const fetchedUsers = await getDocs(query(collection(firestore, 'users'), where(documentId(), 'in', events.map((e) => e.coach))))


      fetchedUsers.forEach((doc) => {
        users[doc.id] = doc.data() as User;
      })

      for (let user of fetchedUsers.docs) {
        this.users[user.id] = {
          id: user.id,
          ...user.data()
        } as User
      }
    }

    this.events = events;
    this.loading = false;
  }


  navigateMonth(val: string) {
    //@ts-expect-error hello
    this.$refs.calendar[val]();
  }

  get calendarEvents() {
    return this.events.map((e: any) => {
      return {
        id: e.id,
        start: e.start.toDate(),
        end: e.end.toDate(),
        name: e.title
      }
    })
  }

  get formattedDate() {
    return format(new Date(this.date), 'MMMM yyyy', {
      locale: nlBE
    })
  }

  async mounted() {
    this.$store.dispatch(ROOT_ACTIONS.SET_BREADCRUMBS, [{ text: 'Webinars', target: '/admin/webinars' }])
  }

  getRandomDarkColor(): string {
    const randomColor = () => Math.floor(Math.random() * 256);
    const r = randomColor(), g = randomColor(), b = randomColor();
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luminance < 40 ? `rgb(${r},${g},${b})` : this.getRandomDarkColor();
  }

  formatDate(date: Date) {
    return format(date, 'dd MMMM HH:mm', {
      locale: this.user.language == 'nl-be' ? nlBE : fr
    })
  }
}
